<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" v-if="$route.query.type === '1'" @click="$router.go(-1)">帮助中心</a>
            <a href="javascript:void(0)" v-if="$route.query.type === '2'" @click="$router.go(-1)">新闻资讯</a>
            <i class="el-icon-arrow-right"></i>
            <template v-if="$route.query.type === '1'">
                <span class="on" v-if="Number(category) === 1">新手指南</span>
                <span class="on" v-if="Number(category) === 2">问题解答</span>
                <span class="on" v-if="Number(category) === 3">规则协议</span>
            </template>
            <template v-if="$route.query.type === '2'">
                <span class="on" v-if="Number(category) === 1">药材资讯</span>
                <span class="on" v-if="Number(category) === 2">药市行情</span>
                <span class="on" v-if="Number(category) === 3">政策法规</span>
                <span class="on" v-if="Number(category) === 4">企业动态</span>
            </template>
        </div>

        <img :src="'./img/banner_help.png'" class="page_banner" v-if="$route.query.type === '1'" />
        <img :src="'./img/banner_new.png'" class="page_banner" v-if="$route.query.type === '2'" />
        <div class="shop_help ql-snow" v-loading="isRequestLoading">
            <div class="head">
                <h3 class="title">{{title}}</h3>

                <div class="contact">
                    <span class="text">如有疑问？请联系</span>
                    <span class="text phone">客服热线：<span class="number">{{consumerPhone}}</span></span>
                </div>
            </div>

            <div class="content notice ql-editor maxImageWidth">
                <div v-html="detail"></div>

                <div class="article_detail_file" v-if="fileJson.length > 0">
                    <div class="text">下载</div>

                    <div class="list">
                        <a class="item" v-for="(item, index) in fileJson" :key="index" target="_blank" :href="imageURL + item.url">
                            <span>{{index + 1}}、{{item.name}}</span><img src="../../assets/img/download.png" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getArticleInfo} from '../../api/shop/article'
  import {consumerPhone, imageURL} from '../../utils/config'
  export default {
    name: "shopArticle",
    data () {
      return {
        imageURL: imageURL,
        title: '',
        detail: '',
        isRequestLoading: false,
        consumerPhone: consumerPhone,
        fileJson: [],
        category: 1
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      if (this.$route.query.type === '1') {
        this.getArticle()
      }
      if (this.$route.query.type === '2') {
        this.getArticle()
      }
    },
    methods: {
      getArticle () {
        this.isRequestLoading = true
        getArticleInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.title = res.data.title;
            this.detail = res.data.detail;
            this.category = res.data.category;
            this.fileJson = res.data.fileJson
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      }
    },
    components: {}
  }
</script>
